// user account actions
angular.module('sq.jobs.events.service',
  ['sq.services.socketIO', 'sq.jobs.model', 'sq.jobs.tracker.service',
    'sq.serverEvents.service',
    'sq.jobs.values', 'sq.user.auth'])
  .factory('jobEvents', jobEventsFactory);

function jobEventsFactory(jobTracker, Jobs, socketIO, jobValues, Auth, serverEvents, $log, $rootScope, $q) {
  const jE = {};

  //private events in addition to our server events
  const events = Object.assign({
    clear: 'clear',
    initialize: 'initialize',
    // Any job update
    update: 'update',
  }, jobValues.server.events);

  let allValidEvents = {};
  let allValidEventsArray = [];

  Object.keys(jobValues.server.events).forEach((type) => {
    Object.keys(jobValues.server.events[type]).forEach((innerType) => {
      allValidEvents[jobValues.server.events[type][innerType]] = 1;
      allValidEventsArray.push(jobValues.server.events[type][innerType]);
    });
  });

  const eventPrefix = 'sq.jobs.events.service:';
  jE.eventPrefix = eventPrefix;

  // Jobs may be deleted by a controller, but we want to send out a broadcast 
  // of this job change
  jE.deleteJob = _updateJob;
  // immutable
  Object.defineProperty(jE, 'events', {
    get: function () {
      return events;
    },
    enumerable: true,
  });

  jE.listen = listen;

  return jE;

  function _forward(state, data, forceFetch = false) {
    if (!data) {
      $log.error('_forward requires data');
      return;
    }

    if (!state || !allValidEvents[state]) {
      $log.error('_forward called without state, or with unrecognized state', arguments);
      return;
    }

    const messageContainer = typeof data === 'string' ? JSON.parse(data) : data;

    // We don't force re-fetch (2nd argument force = false)
    // because this class may be called thousands of times per second
    jobTracker.getOneAsync(messageContainer._id, forceFetch).then((job) => {
      const updatedJob = jobTracker.reconcile(job, messageContainer.data);
      $rootScope.$broadcast(eventPrefix + state, updatedJob);
      $rootScope.$broadcast(eventPrefix + state + ":" + updatedJob._id, updatedJob);
    });
  }

  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.events.service.js');

    socket.on(events.annotation.submitted, (data) => {
      $log.debug('annotation submitted event', data);
      _forward(events.annotation.submitted, data);
    });

    socket.on(events.annotation.remoteUploadProgress, (data) => {
      $log.debug('remoteUploadProgress submitted event', data);
      _forward(events.annotation.remoteUploadProgress, data);
    });

    socket.on(events.annotation.started, (data) => {
      $log.debug('annotation started event', data);
      _forward(events.annotation.started, data);
    });

    socket.on(events.annotation.failed, (data) => {
      $log.debug('annotation failed event', data);
      _forward(events.annotation.failed, data);
    });

    socket.on(events.annotation.completed, (data) => {
      $log.debug('annotation completed event', data);
      _forward(events.annotation.completed, data, true);
    });

    socket.on(events.deletion.completed, (data) => {
      $log.debug('deletion completed event', data);
      _forward(events.deletion.completed, data);
    });

    socket.on(events.annotation.progress, (data) => {
      $log.debug("annotation progress event", data);
      _forward(events.annotation.progress, data);
    });

    socket.on(events.searchIndex.submitted, (data) => {
      $log.debug('searchIndex submitted event', data);
      _forward(events.searchIndex.submitted, data);
    });

    socket.on(events.searchIndex.started, (data) => {
      $log.debug('searchIndex started event', data);
      _forward(events.searchIndex.started, data);
    });

    socket.on(events.searchIndex.progress, (data) => {
      $log.debug('searchIndex progress event', data);
      _forward(events.searchIndex.progress, data);
    });

    socket.on(events.searchIndex.failed, (data) => {
      $log.debug('searchIndex failed event', data);
      _forward(events.searchIndex.failed, data);
    });

    socket.on(events.searchIndex.completed, (data) => {
      $log.debug('searchIndex completed event', data);
      _forward(events.searchIndex.completed, data, true);
    });

    socket.on(events.ancestry.submitted, (data) => {
      $log.debug('ancestry submitted event', data);
      _forward(events.ancestry.submitted, data);
    });

    socket.on(events.ancestry.started, (data) => {
      $log.debug('ancestry started event', data);
      _forward(events.ancestry.started, data);
    });

    socket.on(events.ancestry.progress, (data) => {
      $log.debug('ancestry progress event', data);
      _forward(events.ancestry.progress, data);
    });

    socket.on(events.ancestry.failed, (data) => {
      $log.debug('ancestry failed event', data);
      _forward(events.ancestry.failed, data);
    });

    socket.on(events.ancestry.completed, (data) => {
      $log.debug('ancestry completed event', data);
      _forward(events.ancestry.completed, data, true);
    });
  }

  // could be dangerous; assumes only this servce listens to these events
  function _removeListeners(socket) {
    allValidEventsArray.forEach((event) => {
      socket.removeAllListeners(event);
    });
  }

  // TODO: use something like Jobs.prototype.patch to make simpler, more graphql-like
  // approach to patching
  // TODO: better error handling
  function _updateJob(_job, message, cb) {
    cb(message.data);
    return;
  }

  function listen() {
    // placing here to avoid circular dependency with jobs.tracker.service
    // (since  the initialize and clear methods are handled in results.service.js
    // this is inconsistent )
    $rootScope.$on(Auth.eventPrefix + 'loggedIn', () => {
      $log.debug('received loggedIn in jobs.events.service');

      onLogIn();
    });

    $rootScope.$on(Auth.eventPrefix + 'loggedOut', () => {
      onLogOutOrDown();
    });

    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('unauthorized in job events');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('socketio sent disconnect in jobs.events.annotation.service');

      _removeListeners(socket);
    });
  }

  function onLogOutOrDown() {
    jobTracker.clear();
    $rootScope.$broadcast(eventPrefix + events.clear);
  }

  function onLogIn() {
    //Cannot call jobTracker.clear() on every onLogIn call, because this may introduce
    // a race condition, in which jobTracker is being resolved in a route
    // and at the same time, that route may be resolved with no data at all
    //jobTracker.clear();
    // However, in order to allow routes to retry the request, in case
    // the call here failed for some reason, we need to call
    jobTracker.clear();
    jobTracker.initializeAsync().then(() => {
      $rootScope.$broadcast(eventPrefix + events.initialize, jobTracker.jobs);
    }, (err) => {
      $log.debug('jobTracker.initializeAsync rejected in loggedIn callback in jobs.events.service', err);
    });
  }
}